import React from "react";
import { graphql } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ThreeLinkBlock from "../components/ThreeLinkBlock";

export const AboutPageTemplate = ({ image, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div className="row py-5">
        <div className="col-12 col-sm-10 offset-sm-1">
          <div style={{ position: "relative" }}>
            <PreviewCompatibleImage
              imageInfo={image}
              style={{ maxHeight: "550px" }}
            />
            <div className="hero-tag">
              <div>
                <i className="text-white fas fa-caret-down"></i>
              </div>
              <h2 className="text-white font-cb text-uppercase">About</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-5">
        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
          <PageContent
            className="content pt-2 pb-4 text-line-height"
            content={content}
          />
        </div>
      </div>
      <ThreeLinkBlock />
    </>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.heroImage}
        content={post.html}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
